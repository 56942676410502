@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

body {
    background-color: #404756;
    margin: 0 !important;
    font-family: "Nunito Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button {
    background-color: transparent;
    border: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
#dropdown-menu-align-left::after {
    content: none !important;
}
.row {
    margin-left: 0;
    margin-right: 0;
}
.col {
    padding-left: 0;
    padding-right: 0;
}
.rdt_TableHeader {
    display: none !important;
}
.rdt_Table {
    background: transparent !important;
    font-size: 18px !important;
}
.bdkqKp {
    display: block !important;
}
.rdt_TableHeadRow {
    background: transparent !important;
}
.rdt_TableRow:nth-child(odd) {
    background: rgb(240, 243, 252) !important;
}
.rdt_TableRow:nth-child(even) {
    background: #ffffff !important;
}
.rdt_Pagination {
    background-color: transparent !important;
}
.rdt_TableCol,
.rdt_TableCell {
    display: block !important;
    text-transform: capitalize;
    font-size: 14px !important;
}
.hide {
    display: none;
    transition: 0.3s;
}
.hide ul {
    padding: 0 !important;
}
.hide ul li {
    list-style: none;
    display: inline;
    margin-right: 20px;
}
.rdt_TableRow:hover .hide {
    display: block;
}

.center {
    margin: auto;
}
.loader {
    font-size: 100px;
}

.loadingSpin {
    animation: spin infinite 5s linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes bounceAlpha {
    0% {
        opacity: 1;
        transform: translateX(0px) scale(1);
    }
    25% {
        opacity: 0;
        transform: translateX(10px) scale(0.9);
    }
    26% {
        opacity: 0;
        transform: translateX(-10px) scale(0.9);
    }
    55% {
        opacity: 1;
        transform: translateX(0px) scale(1);
    }
}

.bounceAlpha {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

/* text editor css */
.text-elipssis {
    width: 200px;
    height: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-align: justify;
}
.wrapperDescription {
    background-color: #ffffff;
}

.toolbarDescription {
    border-right: none !important;
    border-top: none !important;
    border-left: none !important;
}

.editorDescription {
    padding: 0 10px !important;
    height: 400px !important;
}

.rdw-image-modal-upload-option-label {
    overflow-wrap: anywhere;
}

.rdw-image-imagewrapper img {
    max-width: 100% !important;
}

/* editor js css */

.codex-editor:nth-of-type(2) {
    display: none;
}

/* sun editor */
.sun-editor .se-wrapper {
    height: 100% !important;
}

/* custom css */
small.text-muted.error.form-text {
    font-style: italic;
    color: #b10404 !important;
}

.form-control:focus {
    box-shadow: none;
    border-color: #ced4da;
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
    box-shadow: none;
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
    box-shadow: none;
}

.btn-primary.focus,
.btn-primary:focus {
    box-shadow: none;
}
